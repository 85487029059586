export const ROUTER = {
  MAIN: '/',
  /* 테스트 버전 */
  TEA: '/tea',
  TEAHOUSE: '/teahouse',
  POST: '/post',
  TEATOGETHER: '/tea-together',
  NOTIFICATION: '/notification',
  TASTINGNOTE: '/tasting-note',
  SHOPPING: '/shopping',
  FESTIVAL: '/festival',
  USER: '/user',
  //Admin
  FESTIVAL_ADMIN: '/festival-admin'
}
